import React from "react";
import { Link } from "gatsby";

import logo from "../img/nshep-logo-icon.png";
import facebook from "../img/social/facebook.svg";
import instagram from "../img/social/instagram.svg";
import twitter from "../img/social/twitter.svg";
import vimeo from "../img/social/vimeo.svg";
import theme from "../templates/theme";

const Footer = class extends React.Component {
  render() {
    return (
      <footer
        style={{ background: theme.primary, colour: theme.primaryColour }}
        className="footer"
      >
        <div className="content has-text-centered">
          <img
            src={logo}
            alt="Kaldi"
            style={{ width: "14em", height: "14em" }}
          />
        </div>
        <div
          style={{ background: theme.primary, colour: theme.primaryColour }}
          className="content has-text-centered"
        >
          <div
            style={{ background: theme.primary, colour: theme.primaryColour }}
            className="container"
          >
            <div className="columns">
              <div className="column is-4">
                <section className="menu">
                  <ul className="menu-list">
                    <li>
                      <Link to="/" className="navbar-item">
                        Home
                      </Link>
                    </li>
                    <li>
                      <Link className="navbar-item" to="/associate-members/">
                      Associate Members
                      </Link>
                    </li>
                    <li>
                      <Link className="navbar-item" to="/about">
                        About
                      </Link>
                    </li>

                    <li>
                      <a
                        className="navbar-item"
                        href="/admin/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Admin
                      </a>
                    </li>
                    <li>
                      <Link className="navbar-item" to="/search">
                        Search
                      </Link>
                    </li>


                  </ul>
                </section>
              </div>
              <div className="column is-4">
                <section>
                  <ul className="menu-list">
                  <li>
                      <Link className="navbar-item" to="/members">
                        Members
                      </Link>
                    </li>
                    <li>
                      <Link className="navbar-item" to="/blog">
                        Latest Stories
                      </Link>
                    </li>
                    <li>
                      <Link className="navbar-item" to="/contact">
                        Contact
                      </Link>
                    </li>
                    <li>
                      <Link className="navbar-item" to="/sitemap">
                        Sitemap
                      </Link>
                    </li>
                  </ul>
                </section>
              </div>
              <div style={{verticalAlign:'bottom'}} className="column is-4 social">
                {/* <a title="facebook" href="https://facebook.com">
                  <img
                    src={facebook}
                    alt="Facebook"
                    style={{ width: "1em", height: "1em" }}
                  />
                </a>
                <a title="twitter" href="https://twitter.com">
                  <img
                    className="fas fa-lg"
                    src={twitter}
                    alt="Twitter"
                    style={{ width: "1em", height: "1em" }}
                  />
                </a>
                <a title="instagram" href="https://instagram.com">
                  <img
                    src={instagram}
                    alt="Instagram"
                    style={{ width: "1em", height: "1em" }}
                  />
                </a>
                <a title="vimeo" href="https://vimeo.com">
                  <img
                    src={vimeo}
                    alt="Vimeo"
                    style={{ width: "1em", height: "1em" }}
                  />
                </a> */}
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
};

export default Footer;
